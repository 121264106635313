import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Contact from "../sections/Orange/Contact";
import Copy from "../sections/Orange/Copy";
import Hero from "../sections/Orange/Hero";
import MarqueeSection from "../sections/Orange/Marquee";


function Index({ pageContext, location }) {


  return (
    <Layout location={location}>
      <SEO title="Home"/>
    <Hero/>
    <MarqueeSection/>
    <Copy/>
    <Contact/>
    </Layout>
  );
}

export default Index;
