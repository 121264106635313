import React, { useEffect, useLayoutEffect } from "react";
import Mike from "../../assets/mike.jpeg";

const EmailHeader = () => {
  return(
    <div className='flex text-black space-x-4 lg:space-x-6 items-center border-b border-gray-100 pb-4 lg:pb-8'>
      <div className='relative h-fit icon-mike'>
      <img src={Mike} className='rounded-full w-20 lg:h-20 aspect-square' alt="Photo of Mike Wargent"></img>
      <div className='absolute bottom-0 right-0 lg:right-1 w-4 h-4 bg-white border-2 border-green-400 rounded-full'>


      </div>
      </div>
      <div className="lg:space-y-1">
        <a href="mailto:mike@orangecreativeco.com">Mike Wargent {`<mike@orangecreativeco.com>`}</a>
        <p className='font-bold hidden lg:block'>Introduction - Your business and Orange Creative Co</p>

      </div>
    </div>
  )
}


const Copy = () => {
  return (
    <section className="pt-[120px] bg-[#FF8A00] px-8">

      <div className='mx-auto max-w-7xl bg-white  p-4 lg:p-8 shadow-md rounded-md'>
        <EmailHeader/>

        <div className="text-black pt-4">

      <p>Welcome,</p><br/>
      <p>Ruthlessly client first. </p><br/>
      <p>Klaviyo experts since before it was cool.</p><br/>
      <p>Orange Creative Co are Klaviyo gold master partners and have helped many merchants migrate from other platforms and see results (as in more revenue) instantly.  We focus on getting you cash flow positive on your investment within 30 days.</p><br/>
      <p>Most of our merchants see an roi of over 80x on their Klaviyo subscription</p><br/>
      <p>{`Yeah, this website sucks, but we aren't fucking website builders.`}</p>

          
        </div>
      </div>

    </section>
  );
};

export default Copy;
