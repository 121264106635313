import React from "react";

function Logo(props) {
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 3230.45 338.55"
        xmlSpace="preserve"
        {...props}
      >
        <path d="M0 330.06V7.02h40.67V134.6h190.49V7.02h40.38v323.04h-40.38v-158.3H40.67v158.3H0zM748.14 330.06L906.44 7.02h33.36l160.06 323.04h-43.6l-43.89-86.9h-178.5l-42.72 86.9h-43.01zm104.17-124.65h142.21l-71.4-143.09-70.81 143.09zM1295.87 330.06h-25.16L1115.04 7.02h43.6l124.65 258.96L1407.94 7.02h44.48l-156.55 323.04zM1661.43 330.06V168.25L1516.01 7.02h50.33l115.58 127.58 115-127.58h50.91l-146.3 161.81v161.23h-40.1zM1896.73 281.49l23.41-34.82c22.82 17.75 46.57 31.31 71.25 40.67 24.67 9.36 49.11 14.05 73.3 14.05 29.85 0 54.08-6.34 72.72-19.02 18.63-12.68 27.94-28.97 27.94-48.87 0-15.8-6.68-28.82-20.04-39.07-13.37-10.24-31.85-16.34-55.45-18.29-5.66-.59-13.85-1.17-24.58-1.76-53.06-3.71-89.25-9.95-108.56-18.73-15.02-7.22-26.44-16.53-34.24-27.95-7.8-11.41-11.7-24.24-11.7-38.48 0-25.94 11.6-47.3 34.82-64.08C1968.81 8.39 1998.95 0 2036.02 0c26.92 0 53.5 4.44 79.74 13.31 26.23 8.88 51.16 22 74.76 39.36l-23.41 33.36c-19.9-16-40.82-28.04-62.76-36.14-21.95-8.09-44.53-12.14-67.74-12.14-24.39 0-44.48 4.73-60.28 14.19-15.8 9.47-23.7 21.31-23.7 35.55 0 5.66 1.27 10.83 3.8 15.51s6.44 9.07 11.7 13.17c13.26 9.95 41.94 16.29 86.03 19.02 20.29 1.17 35.99 2.44 47.11 3.8 32.77 3.71 58.71 14.24 77.83 31.6 19.12 17.36 28.68 38.72 28.68 64.08 0 30.24-13.12 55.11-39.35 74.62-26.24 19.51-60.62 29.26-103.15 29.26-32.19 0-62.08-4.68-89.68-14.05-27.61-9.36-53.9-23.7-78.87-43.01zM2345.83 330.06V5.27h40.67v324.79h-40.67zM2681.98 7.02c50.91 0 91.93 15.02 123.04 45.06 31.11 30.04 46.67 69.15 46.67 117.34 0 48.57-15.32 87.49-45.94 116.75-30.63 29.26-71.88 43.89-123.77 43.89h-138.4V7.02h138.4zm0 37.16h-98.32V292.9h98.32c39.79 0 71.1-11.17 93.93-33.51 22.82-22.33 34.24-52.52 34.24-90.56 0-37.45-11.61-67.59-34.82-90.42-23.23-22.81-54.34-34.23-93.35-34.23zM2963.3 330.06V7.02h253.98v37.16h-213.9v94.8h183.14v37.16h-183.14V292.9h227.06v37.16H2963.3zM399.25 330.23V7.19h253.98v37.16h-213.9v94.8h183.14v37.16H439.34v116.75H666.4v37.16H399.25z" />
      </svg>
    )
  }
  
  export default Logo