import React, {useRef} from "react";
import Styled from "styled-components";
import Footer from "./bluebook/Footer";
import Nav from "./heavyside/Nav";

const LayoutWrapper = Styled.div`


`;

function Layout(props) {
  const containerRef = useRef(null);

  return (
    

    <LayoutWrapper data-scroll-container ref={containerRef}>
      <main className="min-h-[100vh]">
                {props.children}
      </main>
    </LayoutWrapper>

  );
}

export default Layout;
